.support {
    .headerSection {
        min-height: 100vh;
        background-color: #132155;
        background-image: url(../img/support/contactUs/headerBg.png);
        background-size: 100% 100%;
        display: flex;
        align-items: center;

        @media (max-width: 991px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        @media (max-width: 677px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .headerSectionFAQ {
        min-height: 100vh;
        background-color: #132155;
        background-image: url(../img/support/faq/headerBg.png);
        background-size: 100% 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        align-items: center;

        @media (max-width: 991px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        @media (max-width: 677px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .headerTitle {
        font-weight: 500;
        font-size: 50px;
        line-height: 160%;
        text-align: center;

        @media (max-width: 991px) {
            font-size: 40px;
            line-height: 160%;
        }

        @media (max-width: 677px) {
            font-size: 30px;
            line-height: 160%;
        }

        @media (max-width: 300px) {
            font-size: 25px;
            line-height: 130%;
        }
    }

    .headerSubTitle {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        text-align: center;

        @media (max-width: 991px) {
            font-size: 16px;
            line-height: 23px;
        }

        @media (max-width: 677px) {
            font-size: 14px;
            line-height: 20px;
        }
    }

    .contactUs-textBox {
        border: none;
        border-bottom: 2px solid #fff;
        border-radius: 0;
        color: white;
        background-color: transparent;
    }

    .learnMoreBtn {
        background: #0093FF;
        border-radius: 10px;
        width: 100%;
        text-align: center;

        span {
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
            color: #fff;
        }

        @media (max-width: 991px) {
            margin: auto;
        }

        @media (max-width: 677px) {
            margin: auto;

            span {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #fff;
            }
        }
    }


    .accordion__button {
        background-color: transparent !important;
    }

    .accordionTitle {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 29px;
        color: #0093FF;

        @media (max-width: 667px) {
            font-weight: 500;
            font-size: 16px;
        }

        @media (max-width: 300px) {
            font-weight: 500;
            font-size: 15px;
            line-height: 25px;
        }
    }

    .accordionSideItemText {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 29px;
        color: #0093FF;
        width: fit-content;

        @media (max-width: 667px) {
            font-weight: 400;
            font-size: 12px;
        }
    }

    .accordionTitle-inactive {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 29px;
        color: #000;

        @media (max-width: 667px) {
            font-weight: 500;
            font-size: 16px;
        }

        @media (max-width: 300px) {
            font-weight: 500;
            font-size: 15px;
            line-height: 25px;
        }
    }

    .accordionSideItemText-inactive {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 29px;
        color: #000;

        @media (max-width: 667px) {
            font-weight: 400;
            font-size: 12px;
        }
    }


    .accordionContent {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        text-align: center;
        color: #000;

        @media (max-width: 992px) {
            font-size: 14px;
            line-height: 25px;
        }

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 22px;
        }
    }


    .accordion__button {
        background: transparent
    }

    .accordion__panel {
        padding-left: 8px;
        padding-top: 4px !important;
    }


    .accordion__button:before {
        display: none;
    }

    .lh-1 {
        line-height: 1;
    }

    .accordion__button::before {
        display: none !important;
    }

    .accordionIcon {
        font-size: 13px;
    }

    .borderBottomWhite {
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
        // transform: matrix(1, 0, -0.89, -0.45, 0, 0);
    }
}