.company {
    .headerSection {
        min-height: 100vh;
        background-size: 100% 100%;
        display: flex;
        align-items: center;

        @media (max-width: 991px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        @media (max-width: 677px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    #AIpower {
        background-image: url(../img/company/aiPowered/headerBg.png);
    }

    #advantages {
        background-image: url(../img/company/advantages/headerBg.png);
    }

    .mapBg {
        margin-top: 10vh;
        background-image: url(../img/company/aiPowered/mapBg.png);
        position: relative;
    }

    #mapItemDiv1 {
        position: absolute;
        top: 0%;
        left: 30%;
    }

    #mapItemDiv2 {
        position: absolute;
        top: 8%;
        right: 20%;
    }

    #mapItemDiv3 {
        position: absolute;
        top: 50%;
        left: 15%;
    }

    #mapItemDiv4 {
        position: absolute;
        top: 50%;
        right: 25%;
    }

    #mapItemContainer1 {
        transform: rotate(30deg) scale(0.8);

        &:hover {
            background: #20378C;
            transform: rotate(0deg) scale(1);
            transition: 1s;
        }
    }

    #mapItemContainer2 {
        transform: rotate(-30deg) scale(0.8);

        &:hover {
            background: #20378C;
            transform: rotate(0deg) scale(1);
            transition: 1s;
        }
    }

    .mapItemContainer {
        background: #3253CD;
        box-shadow: 0px 5px 35px rgba(50, 83, 205, 0.7);
        border-radius: 26px;
        width: 250px;
        padding-top: 50px;
        position: relative;
    }

    .mapItemContainerMobile {
        background: #3253CD;
        box-shadow: 0px 5px 35px rgba(50, 83, 205, 0.7);
        border-radius: 26px;
        width: 100%;
        padding-top: 90px;
        position: relative;
        min-height: 350px;
    }

    .mapItemTitle {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 49px;
        color: #FFFFFF;
    }

    .powerImgSize {
        width: 130px;

        @media (max-width: 991px) {
            width: 170px;
        }

        @media (max-width: 667px) {
            width: 180px;
        }
    }

    #mapItemImgPos1 {
        position: absolute;
        top: -50px;
        right: 5%;
    }

    #mapItemImgPos2 {
        position: absolute;
        top: -90px;
        right: 5%;
    }

    .headerTitle {
        font-weight: 500;
        font-size: 40px;
        line-height: 160%;
        text-align: center;

        @media (max-width: 991px) {
            font-size: 35px;
            line-height: 160%;
        }

        @media (max-width: 677px) {
            font-size: 25px;
            line-height: 160%;
        }
    }

    .bg-white {
        background-color: white;
    }

    .headerSubTitle {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        text-align: center;

        @media (max-width: 991px) {
            font-size: 16px;
            line-height: 23px;
        }

        @media (max-width: 677px) {
            font-size: 14px;
            line-height: 20px;
        }
    }

    .learnMoreBtn {
        background: #0093FF;
        border-radius: 10px;
        width: fit-content;

        span {
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
            color: #fff;
        }

        @media (max-width: 991px) {
            margin: auto;
        }

        @media (max-width: 677px) {
            margin: auto;

            span {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #fff;
            }
        }

        @media (max-width: 300px) {
            margin: auto;

            span {
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: #fff;
            }
        }
    }

    .productDetailsImgSize {
        width: 65%;
    }

    .sectionTitle {
        font-weight: 700;
        font-size: 43px;
        line-height: 114%;
        align-items: center;
        text-align: center;
        text-transform: capitalize;

        @media (max-width: 991px) {
            font-size: 40px;
            line-height: 160%;
        }

        @media (max-width: 677px) {
            font-size: 30px;
            line-height: 160%;
        }

        @media (max-width: 300px) {
            font-size: 25px;
            line-height: 35px;
        }
    }

    .text-purple {
        color: #132155;
    }

    .advantageDetailsSection {
        background-image: url(../img/company/advantages/advantagesDetailsBg.png);
        background-size: 100% 100%;

        @media (max-width: 991px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        @media (max-width: 677px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .activeCompanyAdvantagesDiv {
        padding: 20px;

        @media (max-width: 300px) {
            padding: 0px;
        }
    }

    .inactiveCompanyAdvantagesDiv {
        padding: 20px;
        transform: scale(0.8);
        opacity: 0.6;
    }

    .companyAdvantagesContainer {
  
    }

    .companyAdvantagesHeaderSection {
        min-height: 200px;
        background-image: url(../img/company/advantages/headerSectionBg.png);
        background-size: 100% 100%;
    }

    .companyAdvantagesDescSection {
        min-height: 300px;
        background: #005EFF;
        border: 1px solid #EAEAEA;
        border-radius: 0 0 15px 15px;
    }

    .companyAdvantagesTitle {
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 134%;
        color: #005EFF;

        @media (max-width: 991px) {
            font-size: 30px;
            line-height: 134%;
        }

        @media (max-width: 677px) {
            font-size: 22px;
            line-height: 30px;
        }
    }

    .companyAdvantagesDesc {
        font-weight: 600;
        font-size: 15px;
        line-height: 25px;
        color: #fff;

        @media (max-width: 991px) {
            font-size: 15px;
            line-height: 25px;
        }

        @media (max-width: 677px) {
            font-size: 14px;
            line-height: 20px;
        }
    }

    .companyAdNext {
        position: absolute;
        top: 50%;
        right: -6%;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 50%;

        @media (max-width: 991px) {
            right: -10%
        }

        @media (max-width: 677px) {
            display: none;
        }
    }

    .companyAdPrev {
        position: absolute;
        top: 50%;
        left: -6%;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 50%;

        @media (max-width: 991px) {
            left: -10%
        }

        @media (max-width: 677px) {
            display: none;
        }
    }

    .slick-dots li button:before {
        color: #fff;
        font-size: 10px;
    }

    .slick-dots li.slick-active button:before {
        color: #fff;
        opacity: 1;
    }

    .aboutUsImg {
        width: 70%;
        margin-left: auto;

        @media (max-width: 991px) {
            width: 90%;
            margin: auto;
        }

        @media (max-width: 677px) {
            display: none;
        }
    }

    .aboutUsContainerPos {
        position: absolute;
        left: 5%;
        // top: 10%;
        background: #FFFFFF;
        box-shadow: 0px 0px 40px 4px rgba(32, 55, 140, 0.1);
        border-radius: 43px;
        width: 50%;

        @media (max-width: 991px) {
            position: absolute;
            width: 90%;
        }

        @media (max-width: 677px) {
            position: relative;
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }

    .sectionAboutUsTitle {
        font-style: normal;
        font-weight: 700;
        font-size: 2rem;
        line-height: 114%;
        text-align: center;
        text-transform: capitalize;

        color: #132155;

        @media (max-width: 991px) {
            font-size: 40px;
            line-height: 160%;
        }

        @media (max-width: 677px) {
            font-size: 30px;
            line-height: 40px;
        }

        @media (max-width: 300px) {
            font-size: 25px;
            line-height: 35px;
        }
    }

    .sectionAboutUsDesc {
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 114%;
        text-align: center;
        color: #132155;

        @media (max-width: 991px) {
            font-size: 16px;
            line-height: 23px;
        }

        @media (max-width: 677px) {
            font-size: 14px;
            line-height: 20px;
        }
    }

    .visMisBg {
        background-image: url(../img/company/aboutUs/visMisBg.png);
    }

    .visMisImgSize {
        width: 80px;
    }

    .visMisTitle {
        font-weight: 600;
        font-size: 30px;
        line-height: 57px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #FFFFFF;

        @media (max-width: 991px) {
            font-size: 30px;
            line-height: 57px;
        }

        @media (max-width: 677px) {
            font-size: 20px;
            line-height: 30px;
        }
    }

}