.navBarLogo {
    width: 120px;

    @media (max-width: 677px) {
        width: 90px;
    }
}

.navbar-darkBlue {
    background: #132155;
}

.navbar-toggler-bar {
    background: white !important;
}

.font-EN-Bold {
    font-family: "Poppins-Bold";
}

.font-EN-Medium {
    font-family: "Poppins-Medium";
}

.font-EN-Regular {
    font-family: "Poppins-Regular";
}

.font-EN-Light {
    font-family: "Poppins-Light";
}

.font-EN-Thin {
    font-family: "Poppins-Thin";
}

.nav-item {
    display: flex;
    align-items: center;
}

.navItemText {
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #fff;
    text-transform: capitalize;

    @media (max-width: 991px) {
        color: white;
    }
}

.navbar-icon {
    color: #fff;

    @media (max-width: 991px) {
        color: white;
    }
}

.navbar-icon2 {
    color: #fff;
    display: none;
    background-color: red;

    @media (max-width: 991px) {
        display: none;
    }
}

.dropdown-toggle::after {
    color: #459DFF;
    font-size: 18px;
    margin-left: 8px;

    @media (max-width: 991px) {
        color: #fff;
    }
}

.navbar-nav .nav-item {
    @media screen and (min-width: 991px) {
        margin-left: 5px;
    }
}

.signUpBtn {
    background: #FFFFFF;
    border-radius: 20px;
    width: fit-content;

    span {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-transform: capitalize;
        color: #20378C;
    }
}


.footer {
    // border-top: 3px solid #001BE0;
    background: #112255;
}

.footerTitleText {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;

    @media (max-width: 667px) {
        font-size: 17px;
        line-height: 25px;
    }

    @media (max-width: 300px) {
        font-size: 16px;
        line-height: 25px;
    }
}

.footerSubTitleText {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 29px;
    color: #FFFFFF;
    mix-blend-mode: normal;
    opacity: 0.75;

    @media (max-width: 667px) {
        font-size: 15px;
        line-height: 24px;
    }

    @media (max-width: 300px) {
        font-size: 14px;
        line-height: 24px;
    }
}

.footerLogoSize {
    width: 180px;

    @media (max-width: 667px) {
        width: 150px;
    }
}

.newsletterSubmitIcon {
    transform: rotate(-45deg);
}

.copywriteText {
    // font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
}

.footer-textBox {
    border: none !important;
    color: white !important;
    background-color: transparent !important;
    border-radius: 0;
}

.floating {
    animation-name: floating;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-top: 5px;
}

.floating2 {
    animation-name: floating2;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-top: 5px;
}


@keyframes floating {
    0% {
        transform: translate(0px, 20px);
    }

    50% {
        transform: translate(0px, -20px);
    }

    100% {
        transform: translate(0px, 20px);
    }
}

@keyframes floating2 {
    0% {
        transform: translate(0px, -30px);
    }

    50% {
        transform: translate(0px, 30px);
    }

    100% {
        transform: translate(0px, -30px);
    }
}

.stickyParticles {
    position: sticky;
    z-index: 1;
}

@media screen and (max-width: 300px) {
    .sidebar-collapse [class*="navbar-expand-"] .navbar-collapse {
        width: 200px !important;
    }

    .nav-open .sidebar-collapse .navbar-translate {
        transform: translate3d(-200px, 0, 0);
    }

    .sidebar-collapse #bodyClick {
        right: 200px;
    }
}


.nav-link:hover {
    background-color: transparent !important;
}

.dropdown-menu-right {
    @media (min-width: 991px) {
        box-shadow: 0 3px 8px 0px rgba(0, 0, 0, 0.17);
        background-color: white !important;
    }
}

.dropdown-item {
    @media (min-width: 991px) {
        box-shadow: 0 3px 3px 0px rgba(0, 0, 0, 0.17);
        background-color: white !important;
        color: black !important;
    }
}

.navbar-nav .nav-item .nav-link:not(.btn):hover {
    @media (max-width: 991px) {
        background-color: transparent !important;
    }
}

.sidebar-collapse .navbar-collapse:before {
    background: #132155 !important;
}

.dropdown-menu {
    @media screen and (min-width: 992px) {
        background: #132155 !important;
    }
}

.dropdown-menu:before {
    @media screen and (min-width: 992px) {
        color: #132155;
    }
}

.dropdown-menu .dropdown-item {
    @media screen and (min-width: 992px) {
        background-color: #132155 !important;
        color: white !important;
    }
}

.sidebar-collapse .navbar .dropdown .dropdown-item {
    @media screen and (max-width: 991px) {
        padding-top: 5px;
        padding-left: 0;
    }
}

.sidebar-collapse .navbar .dropdown.show .dropdown-menu,
.sidebar-collapse .navbar .dropdown .dropdown-menu {
    @media screen and (max-width: 991px) {
        margin-bottom: 0;
    }
}

.getStartedDiv {
    background: #112255;
}

.getStartedBox {
    background-image: url(../img/home/getStartedBox.png);
    background-size: 100% 100%;

    @media (max-width: 991px) {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    @media (max-width: 677px) {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    @media (max-width: 300px) {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 25px;
    }
}

.getStartedBtn {
    background: #0093FF;
    border-radius: 10px;
    width: fit-content;
    float: right;

    span {
        color: white;
    }

    @media (max-width: 667px) {
        float: none;
        margin: auto;
    }

}

.getStartedTitle {
    font-weight: 600;
    font-size: 33px;
    line-height: 24px;

    @media (max-width: 991px) {
        font-size: 33px;
        line-height: 24px;
    }

    @media (max-width: 677px) {
        font-size: 20px;
        line-height: 28px;
    }
}

.text-purple {
    color: #132155;
}

.text-purple2 {
    color: #3253CD;
}

.text-purple3 {
    color: #20378C;
}

.text-purple-light {
    color: #94C5FF;
}

.text-purple-light2 {
    color: #84a1ff;
}


.text-blue {
    color: #005EFF;
}

.cursor-pointer {
    cursor: pointer;
}

.footerNav {
    text-decoration: none !important;
}

.vh-min-100 {
    min-height: 100vh;
}