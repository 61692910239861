.tools {
    .headerSection {
        min-height: 100vh;
        background-color: #132155;
        background-image: url(../img/tools/ctrader/headerBg.png);
        background-size: 100% 100%;
        display: flex;
        align-items: center;

        @media (max-width: 991px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        @media (max-width: 677px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .headerSectionFAQ {
        min-height: 100vh;
        background-color: #132155;
        background-image: url(../img/tools/ctrader/headerBg.png);
        background-size: 100% 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        align-items: center;

        @media (max-width: 991px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        @media (max-width: 677px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .headerTitle {
        font-weight: 500;
        font-size: 50px;
        line-height: 160%;
        text-align: center;

        @media (max-width: 991px) {
            font-size: 40px;
            line-height: 160%;
        }

        @media (max-width: 677px) {
            font-size: 30px;
            line-height: 160%;
        }

        @media (max-width: 300px) {
            font-size: 25px;
            line-height: 130%;
        }
    }

    .headerSubTitle {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        text-align: center;

        @media (max-width: 991px) {
            font-size: 16px;
            line-height: 23px;
        }

        @media (max-width: 677px) {
            font-size: 14px;
            line-height: 20px;
        }
    }

    .downloadImg {
        width: 25%;
    }

    .contactUs-textBox {
        border: none;
        border-bottom: 2px solid #fff;
        border-radius: 0;
        color: white;
        background-color: transparent;
    }

    .learnMoreBtn {
        background: #0093FF;
        border-radius: 10px;
        width: fit-content;
        text-align: center;

        span {
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
            color: #fff;
        }

        @media (max-width: 991px) {
            margin: auto;
        }

        @media (max-width: 677px) {
            margin: auto;

            span {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #fff;
            }
        }

        @media (max-width: 300px) {
            margin: auto;

            span {
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                color: #fff;
            }
        }
    }


    .accordion__button {
        background-color: transparent !important;
    }

    .accordionTitle {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 29px;
        color: #0093FF;

        @media (max-width: 667px) {
            font-weight: 500;
            font-size: 16px;
        }
    }

    .accordionSideItemText {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 29px;
        color: #0093FF;
        width: fit-content;

        @media (max-width: 667px) {
            font-weight: 400;
            font-size: 12px;
        }
    }

    .accordionTitle-inactive {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 29px;
        color: #000;

        @media (max-width: 667px) {
            font-weight: 500;
            font-size: 16px;
        }
    }

    .accordionSideItemText-inactive {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 29px;
        color: #000;

        @media (max-width: 667px) {
            font-weight: 400;
            font-size: 12px;
        }
    }


    .accordionContent {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        text-align: center;
        color: #000;

        @media (max-width: 992px) {
            font-size: 14px;
            line-height: 25px;
        }

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 22px;
        }
    }


    .accordion__button {
        background: transparent
    }

    .accordion__panel {
        padding-left: 8px;
        padding-top: 4px !important;
    }


    .accordion__button:before {
        display: none;
    }

    .lh-1 {
        line-height: 1;
    }

    .accordion__button::before {
        display: none !important;
    }

    .accordionIcon {
        font-size: 13px;
    }

    .borderBottomWhite {
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
        // transform: matrix(1, 0, -0.89, -0.45, 0, 0);
    }

    .allNewsBtn {
        background: #20378C;
        box-shadow: 0px 2px 15px rgba(32, 55, 140, 0.5);
        border-radius: 40.5px;
        width: fit-content;
        float: right;

        span {
            color: white;
        }

        @media (max-width: 667px) {
            float: none;
            margin: auto;
        }
    }

    .newsDiv {
        padding: 15px;

        @media (max-width: 992px) {
            padding: 5px;
        }

        @media (max-width: 677px) {
            padding: 10px;
        }

        @media (max-width: 300px) {
            padding: 0px;
        }
    }

    .newsContainer {
        background: #FFFFFF;
        border: 2px solid rgba(32, 55, 140, 0.1);
        box-shadow: 0px 9px 35px rgba(50, 83, 205, 0.05);
        border-radius: 26px;
    }

    .newsContentHeight {
        min-height: 210px;
    }

    .newsImgContainer {
        img {
            border-radius: 26px 26px 0 0;
            height: 300px;

            @media (max-width: 300px) {
                height: 250px;
            }
        }
    }

    .newsTitle {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.46px;
        color: #000000;

        @media (max-width: 300px) {
            font-size: 18px;
            line-height: 25px;
        }
    }

    .newsDesc {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.46px;
        color: #000000;

        @media (max-width: 300px) {
            font-size: 14px;
            line-height: 18px;
        }
    }

    .newsUserProfile {
        width: 50px;
    }

    .newsPrev {
        position: absolute;
        right: 80px;
        bottom: -5%;
        border: 1.6px solid #20378C;
        border-radius: 50%;
        padding: 10px;
        z-index: 11;

        @media (max-width: 992px) {
            display: none;
            right: 80px;
        }
    }

    .newsNext {
        position: absolute;
        right: 0%;
        bottom: -5%;
        border: 1.6px solid #20378C;
        border-radius: 50%;
        padding: 10px;
        z-index: 11;

        @media (max-width: 992px) {
            display: none;
        }
    }

    .slick-dots li button:before {
        color: #2947A9;
        font-size: 15px;
    }

    .ctraderWebBg {
        background-image: url(../img/tools/ctrader/ctraderWebBg.png);
        background-size: 100% 100%;

        @media (max-width: 991px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        @media (max-width: 677px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .ctraderMobileBg {
        background-image: url(../img/tools/ctrader/ctraderMobileBg.png);
        background-size: 100% 100%;

        @media (max-width: 991px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        @media (max-width: 677px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}