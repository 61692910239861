.trading {
    .headerSection {
        min-height: 100vh;
        background-color: #132155;
        background-image: url(../img/tools/ctrader/headerBg.png);
        background-size: 100% 100%;
        display: flex;
        align-items: center;

        @media (max-width: 991px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        @media (max-width: 677px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .vcgPoolBg {
        min-height: 100vh;
        background-color: #132155;
        background-image: url(../img/trading/vcgPool/headerBg.png);
        background-size: cover;
        display: flex;
        align-items: center;

        @media (max-width: 991px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        @media (max-width: 677px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    #accountType {
        min-height: 100vh;
        background-color: #132155;
        background-image: url(../img/trading/accountType/headerBg.png);
        background-size: 100% 100%;
        display: flex;
        align-items: center;

        @media (max-width: 991px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        @media (max-width: 677px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .headerTitle {
        font-weight: 500;
        font-size: 50px;
        line-height: 160%;
        text-align: center;

        @media (max-width: 991px) {
            font-size: 40px;
            line-height: 160%;
        }

        @media (max-width: 677px) {
            font-size: 30px;
            line-height: 160%;
        }

        @media (max-width: 300px) {
            font-size: 25px;
            line-height: 130%;
        }
    }


    .headerSubTitle {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        text-align: center;

        @media (max-width: 991px) {
            font-size: 16px;
            line-height: 23px;
        }

        @media (max-width: 677px) {
            font-size: 14px;
            line-height: 20px;
        }
    }


    .AcountSubTitle {
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        text-align: center;

        @media (max-width: 991px) {
            font-size: 16px;
            line-height: 23px;
        }

        @media (max-width: 677px) {
            font-size: 14px;
            line-height: 20px;
        }
    }

    .scoreBoardPadding {
        padding-top: 20vh;
    }

    .learnMoreBtn {
        background: #0093FF;
        border-radius: 10px;
        width: fit-content;

        span {
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
            color: #fff;
        }

        @media (max-width: 991px) {
            margin: auto;

            span {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #fff;
            }
        }

        @media (max-width: 677px) {
            margin: auto;
        }

        @media (max-width: 300px) {
            margin: auto;

            span {
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                color: #fff;
            }
        }
    }

    .robotAccountBg {
        min-height: 100vh;
        background-color: #132155;
        background-image: url(../img/trading/accountType/robotBg.png);
        background-size: cover;
        display: flex;
        align-items: center;


        @media (max-width: 991px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: left;
        }

        @media (max-width: 677px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: left;
        }
    }

    .comingSoonPos {
        position: absolute;
        top: 35%;
        left: 0;

        @media (max-width: 677px) {
            top: 10%;
        }
    }

    .comingSoonTitle {
        font-weight: 700;
        font-size: 60px;
        line-height: 120.5%;
        letter-spacing: 0.145em;
        text-transform: uppercase;
        text-shadow: 0px 0px 25px rgba(255, 255, 255, 0.6);

        @media (max-width: 677px) {
            font-size: 40px;
            line-height: 120.5%;
        }
    }

    .robotSize {
        width: 50vh;
        position: sticky;
        z-index: 1000;
        margin: auto;

        @media (max-width: 991px) {
            width: 40vh;
            margin: auto;
        }

        @media (max-width: 677px) {
            width: 40vh;
            margin: auto;
        }
    }

    .podiumBottomPos {
        bottom: -10vh;

        @media (max-width: 991px) {
            bottom: -5vh;
        }

        @media (max-width: 677px) {
            bottom: -5vh;
        }
    }


    .robotNext {
        position: absolute;
        top: 40%;
        right: 0;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 50%;

        @media (max-width: 991px) {
            right: -10%;
            background: rgb(0, 0, 0);
        }

        @media (max-width: 677px) {
            right: 0%;
            background: rgb(0, 0, 0);
        }

        @media (max-width: 300px) {
            right: -3%;
            background: rgb(0, 0, 0);
        }
    }

    .robotPrev {
        position: absolute;
        top: 40%;
        left: 0;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        z-index: 1000;

        @media (max-width: 991px) {
            left: -10%;
            background: rgb(0, 0, 0);
        }

        @media (max-width: 677px) {
            left: 0%;
            background: rgb(0, 0, 0);
        }

        @media (max-width: 300px) {
            left: -3%;
            background: rgb(0, 0, 0);
        }
    }

    .activeTab {
        width: 5px;
        height: 35px;
        background: #FFFFFF;
        box-shadow: 0px 0px 25px rgba(255, 255, 255, 0.8);
    }

    .activeTabText {
        color: #FFFFFF;
        font-weight: 700;
        font-size: 25px;
        line-height: 49px;
        text-transform: uppercase;
        text-shadow: 0px 0px 25px rgba(255, 255, 255, 0.6);

        @media (max-width: 300px) {
            font-size: 20px;
            line-height: 30px;
        }
    }

    .statsTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 33px;
        text-align: center;
        letter-spacing: 0.2px;
        text-transform: capitalize;
        color: rgba(255, 255, 255, 0.6);
    }

    .sectionTitle {
        font-weight: 700;
        font-size: 43px;
        line-height: 114%;
        align-items: center;
        text-align: center;
        text-transform: capitalize;

        @media (max-width: 991px) {
            font-size: 40px;
            line-height: 160%;
        }

        @media (max-width: 677px) {
            font-size: 30px;
            line-height: 160%;
        }

        @media (max-width: 677px) {
            font-size: 25px;
            line-height: 130%;
        }
    }

    .sectionAccountTitle {
        font-weight: 700;
        font-size: 40px;
        line-height: 114%;
        align-items: center;
        text-align: center;
        text-transform: capitalize;

        @media (max-width: 991px) {
            font-size: 40px;
            line-height: 160%;
        }

        @media (max-width: 677px) {
            font-size: 30px;
            line-height: 160%;
        }

        @media (max-width: 300px) {
            font-size: 22px;
            line-height: 130%;
        }
    }

    .text-purple {
        color: #132155;
    }

    .tradeImg {
        width: 130px;

        @media (max-width: 991px) {
            width: 100px;
        }
    }

    .arrowMobile {
        transform: rotate(90deg);
        margin-top: 50px;
        margin-bottom: 50px;
        // width: 80px;
    }

    .tradingStepBg {
        min-height: 100%;
    }

    #lockDropDown {
        .dropdown-menu:before {
            color: #132155;
        }
    
        .dropdown-menu .dropdown-item {
            background-color: #132155 !important;
            color: white !important;
        }
    }
}