.home {
    .headerSection {
        min-height: 100vh;
        background-image: url(../img/home/headerBg.png);
        background-size: 100% 100%;
        display: flex;
        align-items: center;

        @media (max-width: 991px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        @media (max-width: 677px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

    }

    .headerTitle {
        font-weight: 500;
        font-size: 50px;
        line-height: 160%;
        text-align: center;

        @media (max-width: 991px) {
            font-size: 40px;
            line-height: 160%;
        }

        @media (max-width: 677px) {
            font-size: 30px;
            line-height: 160%;
        }

        @media (max-width: 300px) {
            font-size: 25px;
            line-height: 160%;
        }
    }

    .headerSubTitle {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        text-align: center;

        @media (max-width: 991px) {
            font-size: 16px;
            line-height: 23px;
        }

        @media (max-width: 677px) {
            font-size: 14px;
            line-height: 20px;
        }
    }

    .learnMoreBtn {
        background: transparent;
        border-radius: 10px;
        border: 3px solid #0093FF;
        width: fit-content;

        span {
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
            color: #fff;
        }

        @media (max-width: 991px) {
            margin: auto;
        }

        @media (max-width: 677px) {
            margin: auto;

            span {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #fff;
            }
        }
    }

    .sectionTitle {
        font-weight: 700;
        font-size: 43px;
        line-height: 114%;
        align-items: center;
        text-align: center;
        text-transform: capitalize;

        @media (max-width: 991px) {
            font-size: 40px;
            line-height: 160%;
        }

        @media (max-width: 677px) {
            font-size: 30px;
            line-height: 160%;
        }

        @media (max-width: 300px) {
            font-size: 25px;
            line-height: 130%;
        }
    }

    #vanguardBg1 {
        background-image: url(../img/home/vanguardBg1.png);
        background-size: 100% 100%;

        &:hover,
        &:focus {
            background-image: url(../img/home/vanguardBg1Select.png);

            span {
                color: white;
            }
        }
    }

    #vanguardBg2 {
        background-image: url(../img/home/vanguardBg2.png);
        background-size: 100% 100%;

        &:hover,
        &:focus {
            background-image: url(../img/home/vanguardBg2Select.png);

            span {
                color: white;
            }
        }
    }

    #vanguardBg3 {
        background-image: url(../img/home/vanguardBg3.png);
        background-size: 100% 100%;

        &:hover,
        &:focus {
            background-image: url(../img/home/vanguardBg3Select.png);

            span {
                color: white;
            }
        }
    }

    #vanguardBg4 {
        background-image: url(../img/home/vanguardBg4.png);
        background-size: 100% 100%;

        &:hover,
        &:focus {
            background-image: url(../img/home/vanguardBg4Select.png);

            span {
                color: white;
            }
        }
    }

    #vanguardBg5 {
        background-image: url(../img/home/vanguardBg5.png);
        background-size: 100% 100%;

        &:hover,
        &:focus {
            background-image: url(../img/home/vanguardBg5Select.png);

            span {
                color: white;
            }
        }
    }

    #vanguardBg6 {
        background-image: url(../img/home/vanguardBg6.png);
        background-size: 100% 100%;

        &:hover,
        &:focus {
            background-image: url(../img/home/vanguardBg6Select.png);

            span {
                color: white;
            }
        }
    }

    .vanguardDiv {
        text-align: left;
        background: #FFFFFF;
        box-shadow: 0px 9px 35px rgba(50, 83, 205, 0.5);
        border-radius: 26px;
        width: 100%;

        @media (max-width: 677px) {
            width: 100%;
        }
    }

    .vanguardBoxText {
        font-weight: 500;
        font-size: 20px;
        line-height: 122%;
        letter-spacing: 0.2px;
        text-transform: uppercase;

        @media (max-width: 991px) {
            font-size: 20px;
            line-height: 122%;
        }

        @media (max-width: 677px) {
            font-size: 14px;
            line-height: 20px;
        }
    }

    .clientCommentSection {
        background-image: url(../img/home/clientCommentBg.png);
        background-size: 100% 100%;

        @media (max-width: 991px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        @media (max-width: 677px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

    }

    .clientCommentBox {
        max-width: 360px;
        margin: auto;
        background: #FFFFFF;
        box-shadow: 0px 1px 8px rgba(192, 192, 192, 0.6);
        border-radius: 26px;
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .userProfileSize {
        width: 50px;
    }

    .userCommentText {
        font-weight: 500;
        font-size: 17px;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #424242;

        @media (max-width: 677px) {
            font-size: 14px;
            line-height: 20px;
        }
    }

    .userCommentName {
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
        letter-spacing: 0.2px;
        text-transform: capitalize;
        color: #757575;
    }

    .activeCommentSlide {
        background: #20378C;
        // box-shadow: 0px 5px 35px rgba(50, 83, 205, 0.7);
        transform: translateY(-5em);
        transition: 1s;

        .userCommentText,
        .userCommentName,
        .text-purple2 {
            color: #fff;
        }
    }

    .inactiveCommentSlide {
        @media (max-width: 991px) {
            transform: scale(0.7);
        }
    }


    .Wnext {
        position: absolute;
        right: -2%;
        top: 50%;

        @media (max-width: 991px) {
            right: 5%;
            top: 40%;
        }

        @media (max-width: 677px) {
            right: -8%;
            top: 40%;
        }

        @media (max-width: 300px) {
            right: -11%;
            top: 40%;
        }
    }


    .Wprev {
        position: absolute;
        left: -2%;
        top: 50%;
        z-index: 10000;

        @media (max-width: 991px) {
            left: 5%;
            top: 40%;
        }

        @media (max-width: 677px) {
            left: -8%;
            top: 40%;
        }

        @media (max-width: 300px) {
            left: -11%;
            top: 40%;
        }
    }

    .robotDiv {
        background-image: url(../img/home/robotBg.png);
        background-size: 100% 100%;


        @media (max-width: 991px) {
            background-image: url(../img/home/robotBg_mobile.png);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        @media (max-width: 677px) {
            background-image: url(../img/home/robotBg_mobile.png);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
    

    .accountTypeTitle {
        font-weight: 700;
        font-size: 35px;
        line-height: 79px;
        color: #FFFFFF;

        @media (max-width: 991px) {
            font-size: 35px;
            line-height: 160%;
        }

        @media (max-width: 677px) {
            font-size: 30px;
            line-height: 160%;
        }

        @media (max-width: 300px) {
            font-size: 25px;
            line-height: 130%;
        }
    }
    
    .accountTypePos {
        position: absolute;
        top: 3%;
        right: 5%;
    }

    .accountTypeSelectPos {
        position: absolute;
        bottom: 15%;
        left: 5%;
    }

    .robotMascotSize {
        width: 55vh;
        position: sticky;
        z-index: 1000;
        margin: auto;

        @media (max-width: 991px) {
            width: 45vh;
            margin: auto;
        }

        @media (max-width: 677px) {
            width: 40vh;
            margin: auto;
        }
    }

    .activeTab {
        width: 5px;
        height: 35px;
        background: #FFFFFF;
        box-shadow: 0px 0px 25px rgba(255, 255, 255, 0.8);

        @media (max-width: 991px) {
            width: 5px;
            height: 35px;
        }

        @media (max-width: 677px) {
            width: 4px;
            height: 30px;
        }
    }

    .activeTabText {
        color: #FFFFFF;
        font-weight: 700;
        font-size: 25px;
        line-height: 49px;
        text-transform: uppercase;
        text-shadow: 0px 0px 25px rgba(255, 255, 255, 0.6);

        @media (max-width: 991px) {
            font-size: 25px;
            line-height: 49px;
        }

        @media (max-width: 677px) {
            font-size: 18px;
            line-height: 30px;
        }
    }

    .inactiveTabText {
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 49px;
        color: #F4F6F8;
        opacity: 0.8;

        @media (max-width: 991px) {
            font-size: 25px;
            line-height: 49px;
        }

        @media (max-width: 677px) {
            font-size: 18px;
            line-height: 30px;
        }
    }

    .tradingBg {
        background-image: url(../img/home/tradingBg.png);
        background-size: 100% 100%;

        @media (max-width: 991px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        @media (max-width: 677px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .tradingOS_Logo {
        width: 60%;
        aspect-ratio: 3/1;
        object-fit: contain;
        // width: 30%;
        // height: 70px;
    }

    .zeroCommissionSize {
        width: 35%;

        @media (max-width: 991px) {
            width: 70%;
        }

        @media (max-width: 677px) {
            width: 90%;
        }
    }

    .zeroCommissionContent {
        position: absolute;
        top: 0%;
        left: 0;
        right: 0;
        margin: auto;
        min-height: 100vh;
        align-items: center;
        justify-content: center;
    }

    .awardsDiv {
        background-image: url(../img/home/awardBg.png);
        background-size: 100% 100%;


        @media (max-width: 991px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        @media (max-width: 677px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .awardsImgSize {
        width: 80%;
    }

    .slick-dots li button:before {
        color: #fff !important;
    }

    .slick-dots li.slick-active button:before {
        color: #fff !important;
        opacity: 1;
    }
}