.product {
    .headerSection {
        min-height: 100vh;
        background-size: 100% 100%;
        display: flex;
        align-items: center;

        @media (max-width: 991px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        @media (max-width: 677px) {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    #forexExchange {
        background-image: url(../img/product/forexExchange/headerBg.png);
    }

    #preciousMetal {
        background-image: url(../img/product/preciousMetal/headerBg.png);
    }

    #index {
        background-image: url(../img/product/index/headerBg.png);
    }

    #crudeOil {
        background-image: url(../img/product/crudeOil/headerBg.png);
    }

    #contract {
        background-image: url(../img/product/contractDifferent/headerBg.png);
    }

    .headerTitle {
        font-weight: 500;
        font-size: 40px;
        line-height: 160%;
        text-align: center;

        @media (max-width: 991px) {
            font-size: 35px;
            line-height: 160%;
        }

        @media (max-width: 677px) {
            font-size: 25px;
            line-height: 160%;
        }

        @media (max-width: 677px) {
            font-size: 25px;
            line-height: 130%;
        }
    }

    .bg-white {
        background-color: white;
    }

    .headerSubTitle {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        text-align: center;

        @media (max-width: 991px) {
            font-size: 16px;
            line-height: 23px;
        }

        @media (max-width: 677px) {
            font-size: 14px;
            line-height: 20px;
        }
    }

    .learnMoreBtn {
        background: #0093FF;
        border-radius: 10px;
        width: fit-content;

        span {
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
            color: #fff;
        }

        @media (max-width: 991px) {
            margin: auto;
        }

        @media (max-width: 677px) {
            margin: auto;

            span {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #fff;
            }
        }

        @media (max-width: 300px) {
            margin: auto;

            span {
                font-weight: 500;
                font-size: 13px;
                line-height: 18px;
                color: #fff;
            }
        }
    }

    .productDetailsImgSize {
        width: 75%;
    }

    .sectionTitle {
        font-weight: 700;
        font-size: 43px;
        line-height: 114%;
        align-items: center;
        text-align: center;
        text-transform: capitalize;

        @media (max-width: 991px) {
            font-size: 40px;
            line-height: 160%;
        }

        @media (max-width: 677px) {
            font-size: 30px;
            line-height: 160%;
        }

        @media (max-width: 300px) {
            font-size: 25px;
            line-height: 35px;
        }
    }

    .sectionProductTitle {
        font-weight: 700;
        font-size: 40px;
        line-height: 114%;
        align-items: center;
        text-align: center;
        text-transform: capitalize;

        @media (max-width: 991px) {
            font-size: 33px;
            line-height: 160%;
        }

        @media (max-width: 677px) {
            font-size: 25px;
            line-height: 160%;
        }

        @media (max-width: 300px) {
            font-size: 25px;
            line-height: 130%;
        }
    }

    .text-purple {
        color: #132155;
    }

}